<template>
  <div class="card-work-report-tnl" :class="{'showing-green':data.showing}">
    <v-row>
      <v-col class="pl-8 pt-0 pb-2" cols="6">
        <span style="font-weight: bold;font-size: 40px" :style="{'color':data.showing?'#F4F4F4':data.amount == 0?'#E5E5E5':'black'}">{{data.amount}}</span>
      </v-col>
      <v-col class="pr-6 pt-4 pb-2" cols="6" align="end">
        <img v-if="data.image == 1 && data.showing" src="@/assets/image/step1-import.png" alt="">
        <img v-else-if="data.image == 2 && data.showing" src="@/assets/image/step3-import.png" alt="">
        <img v-else-if="data.image == 3 && data.showing" src="@/assets/image/box-tick.png" alt="">
        <img v-else-if="data.image == 4 && data.showing" src="@/assets/image/slash.png" alt="">
        <img v-else-if="data.image == 1" src="@/assets/image/import.png" alt="">
        <img v-else-if="data.image == 2" src="@/assets/image/export.png" alt="">
        <img v-else-if="data.image == 3" src="@/assets/image/box.png" alt="">
        <img v-else-if="data.image == 4" src="@/assets/image/cancel.png" alt="">
      </v-col>
    </v-row>
    <v-row class="mt-0 pt-0">
      <v-col class="pl-8 pt-0 pb-1" cols="12" style="font-size: 14px" :style="{'color':data.showing?'#F4F4F4':'black'}">
        {{data.text}}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "cardWorkReport",
  props:{
    data : Object
  },
  methods:{
    goto(param) {
      this.$router.push(param)
    }
  }
}
</script>

<style scoped>

</style>
