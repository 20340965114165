<template>
  <!-- <div class="list-work mb-6" @click.once="goto(data.mode)">
    <v-row class="pa-0">
      <v-col cols="7" style="color: #59CB33;">
        <span class="pl-4" style="font-size: 20px">{{ data.order_id }}</span>
      </v-col>
      <v-col class="pl-0" cols="5" align="end">
        <span class="text-gray-tnl" style="font-size: 14px">
          {{ data.mode == 'Import' ? 'เปิด' : 'บรรจุ'}}
        </span>
        <span class="pr-3" style="font-size: 20px">
            {{ data.cy_date | formatDate }}
          </span>
      </v-col>
    </v-row>
    <v-divider class="mt-2"></v-divider>
    <v-row class="pa-0 my-0">
      <v-col cols="3" class="text-title-list-work">
        <span class="pl-4">ชื่อบริษัท</span>
      </v-col>
      <v-col cols="9" class="text-data-list-work pl-0">
        <span>{{ data.customer }}</span>
      </v-col>
    </v-row>
    <v-row class="pa-0 my-0" v-if="data.mode == 'Export'">
      <v-col cols="3" class="text-title-list-work py-0">
        <span class="pl-4">ต้นทาง</span>
      </v-col>
      <v-col cols="9" class="text-data-list-work pl-0 pt-0">
        <span>{{ data.release_port }}</span>
      </v-col>
    </v-row>
    <v-row class="pa-0 my-0" v-else>
      <v-col cols="3" class="text-title-list-work py-0">
        <span class="pl-4">ต้นทาง</span>
      </v-col>
      <v-col cols="9" class="text-data-list-work pl-0 pt-0">
        <span>{{ data.cy_place }}</span>
      </v-col>
    </v-row>

    <v-row class="pa-0 my-0" v-if="data.mode == 'Export'">
      <v-col cols="3" class="text-title-list-work py-0">
        <span class="pl-4">Agent</span>
      </v-col>
      <v-col cols="9" class="text-data-list-work pl-0 pt-0">
        <span>{{ data.agent }}</span>
      </v-col>
    </v-row>
    <v-row class="pa-0 my-0" v-if="data.mode == 'Export'">
      <v-col cols="3" class="text-title-list-work py-0">
        <span class="pl-4">Booking</span>
      </v-col>
      <v-col cols="9" class="text-data-list-work pl-0 pt-0">
        <span>{{ data.booking_no }}</span>
      </v-col>
    </v-row>

    <v-row class="pa-0 my-0">
      <v-col cols="5" class="text-title-list-work py-0">
        <span class="pl-4">Container Status</span>
      </v-col>
      <v-col cols="7" class="text-data-list-work pl-0 pt-0">
        <span>{{ data.container_status }}</span>
      </v-col>
    </v-row>
    <v-row class="pa-0 my-0">
      <v-col cols="3" class="text-title-list-work pr-0 pt-0">
        <span class="pl-4">ส่ง/รับ</span>
      </v-col>
      <v-col cols="4" class="text-data-list-work pr-0 pl-0 pt-0">
        <span>{{ data.wh_factory }}</span>
      </v-col>
      <v-col cols="2" class="text-title-list-work pr-0 pl-2 pt-0">
        <span>เวลาส่ง</span>
      </v-col>
      <v-col cols="2" class="text-data-list-work pr-0 pl-4 pt-0">
        <span>{{ data.load_unload_date | formatDate }}</span>
      </v-col>
    </v-row>
    <v-row class="pa-0 my-0">
      <v-col cols="3" class="text-title-list-work pr-0 pt-0">
        <span class="pl-4">คืนตู้</span>
      </v-col>
      <v-col cols="4" class="text-data-list-work pr-0 pl-0 pt-0">
        <span v-if="data.mode == 'Export'">{{ data.return_place_import }}</span>
        <span v-else>{{ data.return_place }}</span>
      </v-col>
      <v-col cols="2" class="text-title-list-work pr-0 pl-2 pt-0">
        <span>เวลาคืนตู้</span>
      </v-col>
      <v-col cols="2" class="text-data-list-work pr-0 pl-4 pt-0">
        <span>{{ data.closing_date | formatDate }}</span>
      </v-col>
    </v-row>
    <v-divider style="background: #59CB33"></v-divider>
    <v-row>
      <v-col class="text-center" cols="4">
        <span class="text-title-list-work">ขนาด</span>
        <br>
        <span class="text-foot-list-work">{{ data.container_size }}’</span>
      </v-col>
      <v-divider vertical style="background: #59CB33;height: 40px;position: relative;top:20px"></v-divider>
      <v-col class="text-center" cols="4">
        <span class="text-title-list-work">Isotype</span>
        <br>
        <span class="text-foot-list-work">{{ data.isotype }}</span>
      </v-col>
      <v-divider vertical style="background: #59CB33;height: 40px;position: relative;top:20px"></v-divider>
      <v-col class="text-center" cols="4">
        <span class="text-title-list-work">จำนวน</span>
        <br>
        <span class="text-foot-list-work">{{ data.qty }}</span>
      </v-col>
    </v-row>
  </div> -->
  <div class="list-work mb-6" @click="goto(data.mode)">
    <v-layout class="pa-4" justify-space-between align-center>
      <v-flex shrink style="color: #59cb33">
        <span class="font-weight-bold" style="font-size: 20px">
          {{ data.order_id }} - {{ data.job_no }}
        </span>
      </v-flex>
      <v-flex shrink class="pl-0 d-flex align-center" align="end">
        <span class="text-gray-tnl mr-2" style="font-size: 14px">
          {{ data.mode == "Import" ? "เปิด" : "บรรจุ" }}
        </span>
        <span style="font-size: 20px">
          {{ data.cy_date | formatDate }}
        </span>
      </v-flex>
    </v-layout>

    <v-divider></v-divider>

    <div class="pa-4">
      <v-layout class="mb-2">
        <v-flex xs3 class="text-title-list-work">
          <span>ชื่อบริษัท</span>
        </v-flex>
        <v-flex xs9 class="text-data-list-work ellipsis-1">
          <span>{{ data.customer }}</span>
        </v-flex>
      </v-layout>

      <v-layout class="mb-2" v-if="data.mode == 'Export'">
        <v-flex xs3 class="text-title-list-work">
          <span>ต้นทาง</span>
        </v-flex>
        <v-flex xs9 class="text-data-list-work ellipsis-1">
          <span>{{ data.release_port }}</span>
        </v-flex>
      </v-layout>

      <v-layout class="mb-2" v-else>
        <v-flex xs3 class="text-title-list-work">
          <span>ต้นทาง</span>
        </v-flex>
        <v-flex xs9 class="text-data-list-work ellipsis-1">
          <span>{{ data.cy_place }}</span>
        </v-flex>
      </v-layout>

      <v-layout class="mb-2" v-if="data.mode == 'Export'">
        <v-flex xs4 class="text-title-list-work">
          <span>Agent</span>
        </v-flex>
        <v-flex xs8 class="text-data-list-work ellipsis-1">
          <span>{{ data.agent }}</span>
        </v-flex>
      </v-layout>

      <v-layout class="mb-2" v-if="data.mode == 'Export'">
        <v-flex xs4 class="text-title-list-work">
          <span>Booking</span>
        </v-flex>
        <v-flex xs8 class="text-data-list-work ellipsis-1">
          <span>{{ data.booking_no }}</span>
        </v-flex>
      </v-layout>

      <v-layout class="mb-2">
        <v-flex shrink class="text-title-list-work mr-4">
          <span>Container Status</span>
        </v-flex>
        <v-flex class="text-data-list-work ellipsis-1">
          <span>{{ data.container_status }}</span>
        </v-flex>
      </v-layout>

      <v-layout class="mb-2" align-center>
        <v-flex xs6>
          <v-layout>
            <v-flex xs4 class="text-title-list-work">ส่ง/รับ</v-flex>
            <v-flex xs8 class="font-14 ellipsis-1 text-data-list-work">
              {{ data.wh_factory }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6>
          <v-layout>
            <v-flex xs5 class="text-title-list-work">เวลาส่ง</v-flex>
            <v-flex xs7 class="font-14 ellipsis-1 text-data-list-work">
              <span>{{ data.load_unload_date | formatDate }}</span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout class="mb-2" align-center>
        <v-flex xs6>
          <v-layout>
            <v-flex xs4 class="text-title-list-work">คืนตู้</v-flex>
            <v-flex xs7 class="font-14 ellipsis-1 text-data-list-work">
              <span v-if="data.mode == 'Export'">
                {{ data.return_place_import }}
              </span>
              <span v-else>{{ data.return_place }}</span>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6>
          <v-layout>
            <v-flex xs5 class="text-title-list-work">เวลาคืนตู้</v-flex>
            <v-flex xs7 class="font-14 ellipsis-1 text-data-list-work">
              <span>{{ data.closing_date | formatDate }}</span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>

    <v-divider style="background: #59cb33"></v-divider>

    <v-row>
      <v-col class="text-center" cols="4">
        <span class="text-title-list-work">ขนาด</span>
        <br />
        <span class="text-foot-list-work">{{ data.container_size }}’</span>
      </v-col>
      <v-divider
        vertical
        style="background: #59cb33; height: 40px; position: relative; top: 20px"
      ></v-divider>
      <v-col class="text-center" cols="4">
        <span class="text-title-list-work">Isotype</span>
        <br />
        <span class="text-foot-list-work">{{ data.isotype }}</span>
      </v-col>
      <v-divider
        vertical
        style="background: #59cb33; height: 40px; position: relative; top: 20px"
      ></v-divider>
      <v-col class="text-center" cols="4">
        <span class="text-title-list-work">จำนวน</span>
        <br />
        <span class="text-foot-list-work">1</span>
        <!-- <span class="text-foot-list-work">{{ data.qty }}</span> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "listWork",
  props: {
    data: Object,
  },
  data() {
    return {
      // isNotImport : true
    };
  },
  methods: {
    goto(param) {
      if (this.data.is_job_success == "1") {
        this.$swal({
          icon: "warning",
          title: "ไม่สามารถดูได้!",
          text: "งานนี้ได้ทำเสร็จเรียบร้อยแล้ว",
          showConfirmButton: true,
        });
      } else if (this.data.status === "REJECT") {
        this.$swal({
          icon: "warning",
          title: "ไม่สามารถดูได้!",
          text: "งานนี้ถูกปฏิเสธ",
          showConfirmButton: true,
        });
      } else {
        this.globalLoading();
        let data = {
          driver_job_id: this.data.driver_job_id,
          mode: param,
        };
        this.$store
          .dispatch("job/getForm", data)
          .then((res) => {
            this.globalHideLoading();
            if (param == "Import")
              this.$router.push(`import/${data.driver_job_id}&mode=Import`);
            else
              this.$router.push(
                `export/${data.driver_job_id}&mode=${data.mode}`
              );
          })
          .catch((res) => {
            if (res.request.status == 401) {
              this.$router.push("/");
            }
            this.globalHideLoading();
          });
      }
    },
  },
};
</script>

<style scoped></style>
