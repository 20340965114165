<template>
  <v-app class="bg-main">
    <Header />

    <div style="">
      <div class="px-3 py-6">
        <v-layout align-center>
          <v-flex
            xs3
            class="work-card-head d-flex flex-column justify-center align-center"
            style="height: 77px"
          >
            <div style="color: #59cb33">
              {{ search.sort }}
            </div>
            <div>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    max-height="20"
                    max-width="20"
                    depressed
                    dark
                    color="#59cb33"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    @change="onFilter(item.name)"
                  >
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-flex>
          <v-flex class="pl-2">
            <div
              @click="scrollHeight >= 179 ? scrollTop() : sort(0)"
              class="work-card px-4"
              :class="{
                'showing-green':
                  (!dataWork1.showing &&
                    !dataWork2.showing &&
                    !dataWork3.showing &&
                    !dataWork4.showing) ||
                  (!isVisible &&
                    (dataWork1.showing ||
                      dataWork2.showing ||
                      dataWork3.showing ||
                      dataWork4.showing)),
              }"
            >
              <v-row>
                <v-col
                  class="pt-0 pb-0"
                  style="font-size: 40px; font-weight: bold"
                  :class="{
                    'showing-white':
                      (!dataWork1.showing &&
                        !dataWork2.showing &&
                        !dataWork3.showing &&
                        !dataWork4.showing) ||
                      (!isVisible &&
                        (dataWork1.showing ||
                          dataWork2.showing ||
                          dataWork3.showing ||
                          dataWork4.showing)),
                  }"
                >
                  <template v-if="this.dataWork1.showing && !isVisible">{{
                    list.job_accept
                  }}</template>
                  <template v-else-if="this.dataWork2.showing && !isVisible">{{
                    list.job_progress
                  }}</template>
                  <template v-else-if="this.dataWork3.showing && !isVisible">{{
                    list.job_complete
                  }}</template>
                  <template v-else-if="this.dataWork4.showing && !isVisible">{{
                    list.job_reject
                  }}</template>
                  <template v-else>{{ list.job_new }}</template>
                </v-col>
                <v-col>
                  <div v-if="scrollHeight >= 179" align="end" class="pr-0">
                    <v-btn icon dark>
                      <v-icon dark large>mdi-chevron-up</v-icon>
                    </v-btn>
                  </div>

                  <div v-if="scrollHeight < 179" class="d-flex justify-end">
                    <v-icon
                      :color="
                        !dataWork1.showing &&
                        !dataWork2.showing &&
                        !dataWork3.showing &&
                        !dataWork4.showing
                          ? 'white'
                          : 'green'
                      "
                      large
                    >
                      mdi-plus-circle-outline
                    </v-icon>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pl-4 mt-0">
                <v-col
                  class="pa-0 pb-2"
                  style="font-size: 14px"
                  :class="{
                    'showing-white':
                      (!dataWork1.showing &&
                        !dataWork2.showing &&
                        !dataWork3.showing &&
                        !dataWork4.showing) ||
                      (!isVisible &&
                        (dataWork1.showing ||
                          dataWork2.showing ||
                          dataWork3.showing ||
                          dataWork4.showing)),
                  }"
                >
                  <template v-if="this.dataWork1.showing && !isVisible">{{
                    dataWork1.text
                  }}</template>
                  <template v-else-if="this.dataWork2.showing && !isVisible">{{
                    dataWork2.text
                  }}</template>
                  <template v-else-if="this.dataWork3.showing && !isVisible">{{
                    dataWork3.text
                  }}</template>
                  <template v-else-if="this.dataWork4.showing && !isVisible">{{
                    dataWork4.text
                  }}</template>
                  <template v-else>งานใหม่</template>
                </v-col>
              </v-row>
            </div>
          </v-flex>
        </v-layout>

        <div class="pb-12 pt-9" v-if="showPanel">
          <v-row>
            <v-col col="6" class="py-0" @click="sort(1)">
              <CardWorkReport :data="dataWork1" />
            </v-col>
            <v-col col="6" class="py-0" @click="sort(2)">
              <CardWorkReport :data="dataWork2" />
            </v-col>
          </v-row>
          <v-row>
            <v-col col="6" @click="sort(3)">
              <CardWorkReport :data="dataWork3" />
            </v-col>
            <v-col col="6" @click="sort(4)">
              <CardWorkReport :data="dataWork4" />
            </v-col>
          </v-row>
        </div>
      </div>

      <v-container class="fixed-report mb-8" id="report" style="z-index: 1">
        <v-row>
          <v-col col="6" class="py-0" @click="sort(1)">
            <CardWorkReport :data="dataWork1" />
          </v-col>
          <v-col col="6" class="py-0" @click="sort(2)">
            <CardWorkReport :data="dataWork2" />
          </v-col>
        </v-row>
        <v-row>
          <v-col col="6" @click="sort(3)">
            <CardWorkReport :data="dataWork3" />
          </v-col>
          <v-col col="6" @click="sort(4)">
            <CardWorkReport :data="dataWork4" />
          </v-col>
        </v-row>

        <div
          class="fixed-topic py-2 mt-6"
          :class="scrollHeight >= 188 ? 'report-title' : ''"
          style="z-index: 1"
        >
          <img
            class="pr-3 pl-2"
            style="position: relative; top: 3px"
            src="@/assets/image/icon-report-green.png"
          />
          <span style="font-weight: bold; font-size: 20px">รายการงาน</span>
        </div>
        <div
          class="pt-3"
          v-for="(x, i) in list.job_list"
          :key="i"
          style="z-index: -1"
        >
          <list-work :data="x" />
        </div>
      </v-container>
    </div>

    <Footer />
  </v-app>
</template>

<script>
import Header from "@/components/header";
import CardWorkReport from "@/components/cardWorkReport";
import ListWork from "@/components/listWork";
import Footer from "@/components/footer";
import { mapState } from "vuex";
export default {
  name: "report",
  components: { Footer, ListWork, CardWorkReport, Header },
  data: () => ({
    scrollHeight: 0,
    showPanel: false,
    isVisible: true,
    togglePanel: false,
    dataWork1: {
      image: 1,
      amount: "",
      text: "",
      showing: false,
    },
    dataWork2: {
      image: 2,
      amount: "",
      text: "",
      showing: false,
    },
    dataWork3: {
      image: 3,
      amount: "",
      text: "",
      showing: false,
    },
    dataWork4: {
      image: 4,
      amount: "",
      text: "",
      showing: false,
    },
    search: {
      filter: "NEW",
      sort: "ทั้งหมด",
    },
    items: [
      { name: "ทั้งหมด", value: "" },
      { name: "วันนี้", value: "" },
      { name: "สัปดาห์นี้", value: "" },
      { name: "เดือนนี้", value: "" },
    ],
  }),
  created() {
    // this.globalLoading();
    this.getList();
  },
  computed: {
    ...mapState({
      list: (state) => {
        return state.common.job_list;
      },
    }),
  },
  watch: {
    scrollHeight: {
      handler() {
        if (this.scrollHeight === 0) {
          this.showPanel = false;
        }
      },
    },
  },
  mounted() {
    // this.lastScrollPosition = window.pageYOffset
    // window.addEventListener("scroll", this.onScroll);

    // listen to scroll event div by id report
    var div = document.getElementById("report");
    div.addEventListener("scroll", this.onScrollDiv);
  },
  beforeDestroy() {
    // window.removeEventListener("scroll", this.onScroll);
    // var div = document.getElementById("report");
    // div.addEventListener("scroll", this.onScrollDiv);
  },
  methods: {
    scrollTop() {
      var div = document.getElementById("report");
      div.scrollTop = 0;
    },
    onFilter(param) {
      this.search.sort = param;
      let sort = "";
      if (this.search.sort == "ทั้งหมด") sort = "";
      else if (this.search.sort == "วันนี้") sort = "TODAY";
      else if (this.search.sort == "สัปดาห์นี้") sort = "WEEK";
      else if (this.search.sort == "เดือนนี้") sort = "MONTH";

      this.$router.push(
        `report?filter=${this.$route.query.filter}&sort=${sort}&show=${this.$route.query.show}`
      );

      this.getList();
    },
    getList() {
      this.globalLoading();

      if (this.$route.query.sort == "ALL") this.search.sort = "ทั้งหมด";
      else if (this.$route.query.sort == "TODAY") this.search.sort = "วันนี้";
      else if (this.$route.query.sort == "WEEK")
        this.search.sort = "สัปดาห์นี้";
      else if (this.$route.query.sort == "MONTH") this.search.sort = "เดือนนี้";

      // this.search.sort = this.$route.query.sort;
      this.search.filter = this.$route.query.filter;

      let data = {
        filter: this.search.filter,
        sort: this.search.sort,
      };

      if (this.search.sort == "ทั้งหมด") data.sort = "";
      else if (this.search.sort == "วันนี้") data.sort = "TODAY";
      else if (this.search.sort == "สัปดาห์นี้") data.sort = "WEEK";
      else if (this.search.sort == "เดือนนี้") data.sort = "MONTH";

      data.filter = this.search.filter;

      this.$store
        .dispatch("common/list", data)
        .then(() => {
          this.changeShow(this.$route.query.show);

          this.dataWork1.amount = this.list.job_accept;
          this.dataWork1.text = "งานที่ได้รับ";
          this.dataWork2.amount = this.list.job_progress;
          this.dataWork2.text = "กำลังดำเนินงาน";
          this.dataWork3.amount = this.list.job_complete;
          this.dataWork3.text = "งานที่เสร็จสมบูรณ์";
          this.dataWork4.amount = this.list.job_reject;
          this.dataWork4.text = "งานที่ปฏิเสธ";
          this.globalHideLoading();
        })
        .catch((res) => {
          if (res.request.status == 401) {
            this.$router.push("/");
          }
          this.globalHideLoading();
        });
    },
    sort(val) {
      if (val == 1) {
        this.dataWork1.showing = true;
        this.dataWork2.showing = false;
        this.dataWork3.showing = false;
        this.dataWork4.showing = false;
        this.search.filter = "ACCEPT";
      } else if (val == 2) {
        this.dataWork1.showing = false;
        this.dataWork2.showing = true;
        this.dataWork3.showing = false;
        this.dataWork4.showing = false;
        this.search.filter = "PROGRESS";
      } else if (val == 3) {
        this.dataWork1.showing = false;
        this.dataWork2.showing = false;
        this.dataWork3.showing = true;
        this.dataWork4.showing = false;
        this.search.filter = "COMPLETE";
      } else if (val == 4) {
        this.dataWork1.showing = false;
        this.dataWork2.showing = false;
        this.dataWork3.showing = false;
        this.dataWork4.showing = true;
        this.search.filter = "REJECT";
      } else {
        this.dataWork1.showing = false;
        this.dataWork2.showing = false;
        this.dataWork3.showing = false;
        this.dataWork4.showing = false;
        this.search.filter = "NEW";
      }
      // set query string to param filter
      this.$router.push(
        `report?filter=${this.search.filter}&sort=${this.search.sort}&show=${val}`
      );
      this.getList();
    },
    changeShow(val) {
      if (val == 1) {
        this.dataWork1.showing = true;
        this.dataWork2.showing = false;
        this.dataWork3.showing = false;
        this.dataWork4.showing = false;
        this.search.filter = "ACCEPT";
      } else if (val == 2) {
        this.dataWork1.showing = false;
        this.dataWork2.showing = true;
        this.dataWork3.showing = false;
        this.dataWork4.showing = false;
        this.search.filter = "PROGRESS";
      } else if (val == 3) {
        this.dataWork1.showing = false;
        this.dataWork2.showing = false;
        this.dataWork3.showing = true;
        this.dataWork4.showing = false;
        this.search.filter = "COMPLETE";
      } else if (val == 4) {
        this.dataWork1.showing = false;
        this.dataWork2.showing = false;
        this.dataWork3.showing = false;
        this.dataWork4.showing = true;
        this.search.filter = "REJECT";
      } else {
        this.dataWork1.showing = false;
        this.dataWork2.showing = false;
        this.dataWork3.showing = false;
        this.dataWork4.showing = false;
        this.search.filter = "NEW";
      }
    },
    openPanel() {
      this.isVisible = true;
      if (this.togglePanel) {
        this.isVisible = false;
        this.togglePanel = false;
      } else this.togglePanel = true;
    },
    openSelect() {
      console.log("open");
      // this.$refs.select_filter
      // this.$refs.select_filter.click();
    },
    onScrollDiv() {
      // get scrollY div by id report and set to scrollHeight variable
      this.scrollHeight = document.getElementById("report").scrollTop;
      if (this.scrollHeight > 170 && !this.togglePanel) {
        this.isVisible = false;
      } else if (this.scrollHeight == 0 && !this.togglePanel) {
        this.isVisible = true;
      }
    },
    onScroll() {
      if (window.top.scrollY > 20 && !this.togglePanel) {
        this.isVisible = false;
      } else if (window.top.scrollY == 0 && !this.togglePanel) {
        this.isVisible = true;
      }
      // if (window.pageYOffset < 0) {
      //   return
      // }
      // if (Math.abs(window.pageYOffset - this.lastScrollPosition) < this.scrollOffset) {
      //   return
      // }else{
      //   if (window.pageYOffset < this.lastScrollPosition){
      //     this.isVisible = true
      //   }else{
      //     this.isVisible = false
      //   }
      //   this.lastScrollPosition = window.pageYOffset
      // }
    },
  },
};
</script>

<style scoped>
.report-title {
  position: sticky;
  top: -25px;
}
</style>
